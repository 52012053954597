import React, {FC, useEffect, useState} from "react";
import {
  ClickAwayListener,
  MenuItem,
  Popover, Icon,
} from "@material-ui/core";
import LanguageIcon from '../../../img/earth.svg';
import {useHistory} from 'react-router-dom';

import strings from "../../../util/i18n/strings";
import {YOLLA_LANG} from "../../../api/root";
import {TUser} from "../../../store/types";
import DefaultUser from '../../../img/user_empty.svg'
import {languages} from "../../../util/i18n/lang";

import s from "./header.module.scss";


type Props = {
  user: TUser | null,
  siteUrl: string,
  stateUser: boolean,
  openDownload: () => void,
  openSettings: () => void,
  openDelete: () => void,
  openLogout: () => void,
}

type StateProps = {
  isSubmenuOpened: {
    call: boolean,
    earn: boolean,
    accountSettings: boolean,
    lang: boolean
  },
}

const HeaderContent: FC<Props> = ({
                                    siteUrl,
                                    stateUser,
                                    openDownload,
                                    openSettings,
                                    openDelete,
                                    openLogout,
                                    user,
                                  }) => {

  const [state, setState] = useState<StateProps>({
    isSubmenuOpened: {
      call: false,
      earn: false,
      accountSettings: false,
      lang: false
    },
  })
  let history = useHistory()

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>();
  const [initialLang, setInitialLang] = useState<string>('en');

  const handleClickAway = (prop: 'call' | "earn" | "accountSettings" | "lang") => {
    const isSubmenuOpened = state.isSubmenuOpened;
    isSubmenuOpened[prop] = false;
    setState({
      ...state,
      isSubmenuOpened
    });
  }

  const handleSubmenu = (prop: 'call' | "earn" | "accountSettings" | "lang" | null) => {
    const isSubmenuOpened = state.isSubmenuOpened;
    Object.keys(isSubmenuOpened).forEach((key) => {
      if (key === 'call' || key === "earn" || key === "accountSettings" || key === "lang") {
        isSubmenuOpened[key] = key === prop;
      }
    })
    setState({
      ...state,
      isSubmenuOpened
    });
  };

  const closeAccountMenu = () => {
    setAnchorEl(null)
    const isSubmenuOpened = state.isSubmenuOpened;
    isSubmenuOpened.accountSettings = false;
    setState({
      ...state,
      isSubmenuOpened
    });
  };

  const openDownloadModal = () => {
    closeAccountMenu();
    openDownload()
  };

  const openSettingsModal = () => {
    closeAccountMenu();
    openSettings()
  }

  const openDeleteAccountModal = () => {
    closeAccountMenu();
    openDelete()
  };

  const openLogoutModal = () => {
    closeAccountMenu();
    openLogout()
  };

  useEffect(() => {
    const lang = localStorage.getItem(YOLLA_LANG);
    if (lang) {
      setInitialLang(lang)
    }
  }, [])

  const changeLocale = (lang: string) => {
    localStorage.setItem(YOLLA_LANG, lang);
    setInitialLang(lang);

    let url = new URL(window.location.href);

    if (url.searchParams.has('lang')) {
      url.searchParams.set('lang', lang);
      window.location.href = url.toString();
    } else {
      history.go(0);
    }
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const arrayLang = languages.filter(el => el !== initialLang)

  return (
    <div className={s.wrapper}>
      <div className={s.wrapperLogo}>
        <a href={siteUrl} className={s.logo}/>
      </div>
      <div className={s.wrapperNavbar}>
        <ul className={s.topmenu}>
          <li className={s.dropdownItem}>
            <div
              className={s.pointer}
              onMouseEnter={() => handleSubmenu("call")}
              onMouseLeave={() => handleClickAway('call')}
            >
              <a>
                {strings.header.menu_call}
              </a>
              <span className={s.caret}/>
              {state.isSubmenuOpened.call && (
                <div className={s.wrapperEarn}>
                  <div className={s.fakeEarn}/>
                  <ul className={`${s.dropdownMenu} ${s.wide} ${s.earnWrap}`}>
                    <li>
                      <a href={`${siteUrl}/rates`} className={s.a}>
                        {strings.header.menu_call_item__rates}
                      </a>
                    </li>
                    <li>
                      <a href={`${siteUrl}/country-codes`} className={s.a}>
                        {strings.header.menu_call_item__country_codes}
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </li>

          <li className={`${s.dropdownItem} ${s.help}`}>
            <a href={`${siteUrl}/sms`}>{strings.header.menu_sms}</a>
          </li>

          <li className={`${s.dropdownItem} ${s.earn}`}>
            <div
              className={s.pointer}
              onMouseEnter={() => handleSubmenu("earn")}
              onMouseLeave={() => handleClickAway('earn')}
            >
              <a>
                {strings.header.menu_earn}
              </a>
              <div className={s.caret}/>
              {state.isSubmenuOpened.earn && (
                <div className={s.wrapperEarn}>
                  <div className={s.fakeEarn}/>
                  <ul className={`${s.dropdownMenu} ${s.wide} ${s.earnWrap}`}>
                    <li>
                      <a href={`${siteUrl}/free-credits`}>
                        {strings.header.menu_earn_item__calls}
                      </a>
                    </li>
                    <li>
                      <a href={`${siteUrl}/resellers`}>
                        {strings.header.menu_earn_item__program}
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </li>
          <li className={`${s.dropdownItem} ${s.blog}`}>
            <a href={`${siteUrl}/blog`}>{strings.header.menu_blog}</a>
          </li>
          <li className={`${s.dropdownItem} ${s.help}`}>
            <a href={`${siteUrl}/faq`}>{strings.header.menu_help}</a>
          </li>
          <li className={`${s.dropdownItem} ${s.lang}`}>
            <div
              className={s.pointer}
              onMouseEnter={() => handleSubmenu("lang")}
              onMouseLeave={() => handleClickAway('lang')}
            >
              <a>
                {initialLang}
              </a>
              <span>
                  <Icon className={s.iconLang}>
                    <img src={LanguageIcon} alt=''/>
                  </Icon>
                </span>
              {state.isSubmenuOpened.lang && (
                <div className={s.wrapperLang}>
                  <div className={s.fake}/>
                  <ul className={`${s.dropdownMenu} ${s.wide} ${s.languages}`}>
                    {arrayLang.map((el, index) => (
                      <li key={index}>
                        <a onClick={() => {
                          changeLocale(el);
                        }}
                        >
                          {el}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </li>
        </ul>

        {stateUser && (
          <div className={s.accountSettings}>
            <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={() => handleClickAway("accountSettings")}
            >
              <div
                className={s.wrapperAccount}
                onClick={event => {
                  handleSubmenu('accountSettings');
                  setAnchorEl(event.currentTarget);
                }}>
                <div className={s.avatar}>
                  <img src={user?.avatar ? user.avatar : DefaultUser} alt=""/>
                </div>
                <div className={s.menuButton}>
                  {strings.header.menu_account}
                </div>
                <div className={s.caret}/>
              </div>
            </ClickAwayListener>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={() => {
                handleClickAway("accountSettings")
                setAnchorEl(null);
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem className={s.menuItem} onClick={openDownloadModal}>
                {strings.header.menu_account_item__download}
              </MenuItem>
              <MenuItem className={s.menuItem} onClick={openSettingsModal}>
                {strings.header.menu_account_item__notification}
              </MenuItem>
              <MenuItem className={s.menuItem} onClick={openDeleteAccountModal}>
                {strings.header.menu_account_item__delete}
              </MenuItem>
              <MenuItem className={s.menuItem} onClick={openLogoutModal}>
                {strings.header.menu_account_item__logout}
              </MenuItem>
            </Popover>
          </div>
        )}
      </div>
    </div>
  )
}

export default HeaderContent
